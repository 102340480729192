@tailwind base;
@tailwind components;
@tailwind utilities;

.triangle {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #FB923C; /* Adjust color to match */
  margin: 0 10px;
}
